<template>
  <msg :config="config" :type='type' :promptVisible="visible" class="employ_msg" :title="title" :cancelDialog="cancelDialogs" :confirmDialog='tipsConfirm' :isdeling='isdeling'>
      <div class="download_tip">请选择文件下载的格式</div>
      <el-radio-group v-model="downloadType" @change="selectType" class="download_select">
        <el-radio label="1">按源格式下载</el-radio>
        <el-radio label="2">按PDF格式下载</el-radio>
        <el-radio label="3">按OFD格式下载</el-radio>
      </el-radio-group>

  </msg>
</template>

<script>
  export default {
    props: {
      visible: {
        type: Boolean,
      },
      isdeling : {
        type: Boolean,
        default: false,
      },
      cancelDialog: {
        type: Function,
        default: () => { },
      },
      confirmDialog: {
        type: Function,
        default: () => { },
      },
    },
    components: {

    },
    data() {
      return {
        config: {
          top: '25vh',
          width: '20rem',
          center: true,
          btnTxt: ['确定','取消'],
        },
        // type:'success',
        type:'warning',
        title:'下载',
        // type:'error',
        downloadType: '1'
      };
    },
    methods: {
      cancelDialogs(){
        this.cancelDialog();
      },
      tipsConfirm(){
        this.confirmDialog();
      },
      selectType(){
        this.$emit('selectType',this.downloadType);
      }
    },
    watch:{
    }
  };
</script>

<style lang="less" scoped>
  .download_tip {
    text-align: center;
    font-size: 16px;
    margin-bottom: 30px;
  }
  .download_select {
    width: 100%;
    /deep/ label {
      width: 100%;
      padding-left: 100px;
      margin-bottom: 20px;
      font-size: 16px !important;
    }
  }
</style>
